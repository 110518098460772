import { createTheme, Theme } from "@mui/material/styles";
import { OdlThemeType } from "@odl/core";
import { odlLightThemePalette } from "@odl/core/styles/odlProTheme";
import typography from "@odl/core/styles/typography";
import NotoSansBold from "assets/fonts/NotoSans-Bold.ttf";
import NotoSansMedium from "assets/fonts/NotoSans-Medium.ttf";
import NotoSansRegular from "assets/fonts/NotoSans-Regular.ttf";
import NotoSansSemiBold from "assets/fonts/NotoSans-SemiBold.ttf";
import ObjectiveWOFF from "assets/fonts/Objective.woff";
import ObjectiveWOFF2 from "assets/fonts/Objective.woff2";
import { flexGap } from "styles/flexGap";
import { headerShadow } from "styles/headerShadow";
import { layoutTransition } from "styles/layoutTransition";
import { objectivePalette } from "styles/objectivePalette";

declare module "@mui/material/styles" {
  interface DefaultTheme extends Theme {}
  interface BreakpointOverrides {
    xxl: true;
    xxxl: true;
  }
}

// prettier-ignore
const standardFontFamily = [
  "Noto Sans",
  "sans-serif",
].join(",");

const theme: OdlThemeType & Theme = createTheme({
  // Color palette
  palette: {
    ...odlLightThemePalette,
    objective: objectivePalette,
  },
});

export const defaultTheme = createTheme({
  // Mixins
  mixins: {
    flexGap,
    layoutTransition,
    headerShadow,
  },
  // Color palette
  palette: theme.palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // iPad portrait
      md: 1024, // iPad landscape
      lg: 1280, // laptop (mackbook)
      xl: 1440, // desktop
      xxl: 1600, // desktop
      xxxl: 1920, // desktop (design)
    },
  },
  // Spacing - based on ODL factor4
  spacing: (factor: number) => `${factor * 4}px`,
  // Typography - default typography on @odl/core package
  typography,
  components: {
    // Normalize typography - separate styles from semantic
    MuiCssBaseline: {
      styleOverrides: {
        h1: {
          margin: 0,
        },
        h2: {
          margin: 0,
        },
        h3: {
          margin: 0,
        },
        h4: {
          margin: 0,
        },
        h5: {
          margin: 0,
        },
        h6: {
          margin: 0,
        },
        p: {
          margin: 0,
        },
        button: {
          fontFamily: standardFontFamily,
        },
        body: {
          backgroundColor: theme.palette.background.paper,
        },
        "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
          backgroundClip: "text !important",
        },
        "@font-face": {
          fontFamily: "Objective",
          fontStyle: "normal",
          fontDisplay: "swap",
          fontWeight: "normal",
          src: `url(${ObjectiveWOFF2}) format('woff2'), url(${ObjectiveWOFF}) format('woff')`,
        },
        fallbacks: [
          {
            "@font-face": {
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontDisplay: "block",
              fontWeight: 400,
              src: `url("${NotoSansRegular}") format('truetype')`,
            },
          },
          {
            "@font-face": {
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontDisplay: "block",
              fontWeight: 500,
              src: `url("${NotoSansMedium}") format('truetype')`,
            },
          },
          {
            "@font-face": {
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontDisplay: "block",
              fontWeight: 600,
              src: `url("${NotoSansSemiBold}") format('truetype')`,
            },
          },
          {
            "@font-face": {
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontDisplay: "block",
              fontWeight: 700,
              src: `url("${NotoSansBold}") format('truetype')`,
            },
          },
        ],
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: `0 0 0 2px inset ${theme.palette.primary.main}`,
            borderBottomColor: "transparent",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          textarea: {
            "&:hover": {
              background: theme.palette.grey[400],
              boxShadow: `0 0 0 2px inset ${theme.palette.primary.main}`,
              borderBottomColor: "transparent",
              outline: "none",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: objectivePalette.purple.main,
        },
        outlined: {
          position: "relative",
          transform: "unset",
          color: theme.palette.text.primary,
          fontWeight: 600,
          fontSize: 14,
          paddingBottom: 8,
        },
        root: {
          "&.Mui-disabled": {
            color: objectivePalette.dark.neutral,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          margin: "0 !important",
          paddingTop: 8,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.2,
        },
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "inherit" },
          style: {
            fontWeight: 700,
          },
        },
      ],
      styleOverrides: {
        root: {
          cursor: "pointer",
          textDecoration: "none",
          "&:hover": {
            color: theme.palette.primary.light,
            textDecoration: "underline",
          },
          "&:focus-visible": {
            color: theme.palette.primary.dark,
            textDecoration: "underline",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          maxWidth: 200,
        },
        tooltip: {
          backgroundColor: objectivePalette.dark.night,
          padding: "8px 12px",
          fontSize: "14px",
          lineHeight: 1.5,
          fontWeight: 400,
          textAlign: "center",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "7.5px 16px",
          "&.MuiMenuItem-root.Mui-selected": {
            borderLeftWidth: 4,
            paddingLeft: 12,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "+ .MuiCard-root": {
            borderTop: `1px solid ${theme.palette.background.default}`,
          },
          marginBottom: "0 !important",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "none",
            boxShadow: `0 0 0 1px ${theme.palette.background.default}`,
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "12px 24px 10.4px 20px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px 10.4px 20px",
          "&:last-child": {
            paddingBottom: "10.4px",
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          backgroundColor: theme.palette.grey[400],
        },
        root: {
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
          "&:focus-visible": {
            backgroundColor: theme.palette.background.paper,
          },
          "&:active": {
            backgroundColor: theme.palette.background.info,
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            pointerEvents: "all",
          },
          "&.clickDisabled:hover": {
            cursor: "default",
          },
          "&.clickDisabled:active": {
            backgroundColor: theme.palette.grey[400],
            cursor: "default",
          },
        },
      },
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          padding: "16px 12px",
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        square: true,
        TransitionProps: {
          mountOnEnter: true,
          unmountOnExit: true,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.background.default,
          "&.tabDivider": {
            borderWidth: "0 0 2px",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          padding: "12px 12px 8px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiFilledInput-root.MuiFilledInput-root": {
            ".MuiAutocomplete-input": {
              padding: theme.spacing(1.5),
            },
          },
        },
      },
    },
  },
});
