import React from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { css } from "@emotion/react";

type Props = AccordionProps;

const defaultTransitionProps: TransitionProps = {
  mountOnEnter: true,
  unmountOnExit: true,
};

const Accordion: React.FC<Props> = React.forwardRef(({ children, TransitionProps, ...props }, ref) => {
  return (
    <StyledMuiAccordion ref={ref} TransitionProps={TransitionProps || defaultTransitionProps} {...props}>
      {children}
    </StyledMuiAccordion>
  );
});

const StyledMuiAccordion = styled(MuiAccordion)(
  ({ theme }) => css`
    &::before {
      background-color: transparent;
    }

    &.Mui-expanded {
      box-sizing: border-box;
    }
  `
);

export default Accordion;
